@import '../../website_theme/plugins/slick/slick.css';
@import '../../website_theme/plugins/slick/slick-theme.css';

div.custom-slider {
    overflow: hidden;
}
.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.hero-text h2 {
    margin-bottom: 50px;
}

.hero-text .hero {
    position: relative;
}

.hero-text .hero .hero-slide a:hover span {
    color: #033a71;
}

.hero .hero-slide img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
}

.hero .hero-slide img.cover-top {
    object-position: top center;
}

.hero .hero-slide img.cover-center {
    object-position: center center;
}

.hero .hero-slide img.cover-bottom {
    object-position: bottom center;
}

.hero .hero-slide .header-content {
    top: 20%;
    margin-left: 8rem;
    max-width: 550px;
    width: 100%;
    padding: 2rem;
}

.slide-content {
    padding: 10px 20px 10px 0;
}

.slide-content .h1 {
    font-size: 62px;
}

.slick-dots {
    position: absolute;
    bottom: 10px;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
    border: 0;
    outline: none;
}

.slick-dots li button::before {
    font-size: 18px;
    color: #fff;
    opacity: 1;
}

.slick-active button {
    background: #d60e96;
}

/** Text Animation **/

@-webkit-keyframes fadeInUpSD {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUpSD {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInUpSD {
    -webkit-animation-name: fadeInUpSD;
    animation-name: fadeInUpSD;
}

.slick-active .slide-content {
    animation-name: fadeInUpSD;
    animation-duration: 2s;
    opacity: 1;
    width: 100%;
    padding: 10px 20px 30px 0;
}

/* Media Queries */

@media (max-width: 0px) {
    .hero-text .hero .hero-slide a {
        padding-top: 0.8rem;
    }

    .hero-text .hero .hero-slide a span {
        font-size: 20px;
        margin-top: 0.5rem;
    }

    .hero .hero-slide .header-content {
        left: 10%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        margin: 0 auto;
    }
}